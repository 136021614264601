//$enable-rounded: false;

@import 'custom';

body {
  // &:before {
  //   content: "enable rounded: " + $enable-rounded
  // }
}

.full-window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

@import "~bootstrap/scss/bootstrap";
